import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { companiesActions, errorActions } from '../actions';
import { changeSubscriptionActions } from '../actions/change-subscription.actions';
import { billingService } from '../services';
import { SubscriptionType } from '../types/billing/SubscriptionType';

function* watchSaveSubscription(action: ActionType<typeof changeSubscriptionActions.saveSubscription>) {
    yield put(changeSubscriptionActions.setSubscriptionSavingFlag(true));

    const { companyId, subscriptionType, subscriptionExpiration } = action.payload;

    let isTrial = false;
    let newSubscriptionType = subscriptionType;

    if (subscriptionType === SubscriptionType.trial) {
        newSubscriptionType = SubscriptionType.pro;
        isTrial = true;
    }

    try {
        yield call(billingService.saveSubscription, companyId, newSubscriptionType, isTrial, subscriptionExpiration);
        yield put(companiesActions.companySubscriptionChange(companyId, subscriptionType, subscriptionExpiration));
        yield put(changeSubscriptionActions.hide());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(changeSubscriptionActions.setSubscriptionSavingFlag(false));
    }
}

export function* watchChangeSubscription() {
    yield takeEvery(getType(changeSubscriptionActions.saveSubscription), watchSaveSubscription);
}
