import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { directBiddingDisclaimerActions } from '../actions/direct-bidding-agreement-disclaimer.actions';
import { accountActions } from '../constants';
import { accountService } from '../services';
import { errorActions } from '../actions';
import { user } from '../user';
import { saveAs } from 'file-saver';

function* watchConfirmRequest() {
    try {
        yield call(accountService.setDirectBiddingAgreementSigned);
        user.setDirectBiddingAgreementSigned(true);
        yield put(directBiddingDisclaimerActions.hide(true));
        yield put(directBiddingDisclaimerActions.confirmSuccess());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(directBiddingDisclaimerActions.confirmFailure());
    }
}

function* watchDownloadPdfRequest() {
    try {
        const file: { name: string, blob: Blob } = yield call(accountService.downloadDirectBiddingAgreementPdf);
        saveAs(file.blob, file.name);
        yield put(directBiddingDisclaimerActions.downloadPdfSuccess());
    } catch (e) {
        yield put(directBiddingDisclaimerActions.downloadPdfFailure());
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLogout() {
    yield put(directBiddingDisclaimerActions.reset());
}

export function* watchDirectBiddingDisclaimer() {
    yield takeLatest(getType(directBiddingDisclaimerActions.confirmRequest), watchConfirmRequest);
    yield takeLatest(getType(directBiddingDisclaimerActions.downloadPdfRequest), watchDownloadPdfRequest);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
