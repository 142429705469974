import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { favoriteBrokerDealersActions } from '../actions/favorite-broker-dealers.actions';
import { AppState } from '../types/state/AppState';
import { errorActions } from '../actions';
import { companiesService } from '../services';
import { apiUtils } from '../utils/api.utils';
import { accountActions } from '../constants';

function* watchFetchFavoriteBrokerDealers() {
    try {
        const response: number[] = yield call(companiesService.getFavorites);
        const favorites = apiUtils.normalize(response, (r) => r, () => true);
        yield put(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersSuccess(favorites))
    } catch (e) {
        yield put(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersFailure());
        yield put(errorActions.unexpectedError(e))
    }
}

function* deleteFromFavorites(dealerId: number) {
    try {
        yield call(companiesService.deleteFromFavorites, dealerId);
        const { favorites } = yield select((state: AppState) => state.favoriteBrokerDealers);
        const newFavorites = { ...favorites };
        delete newFavorites[dealerId];
        yield put(favoriteBrokerDealersActions.updateFavoriteBrokerDealerSuccess(newFavorites, dealerId))
    } catch (e) {
        yield put(errorActions.unexpectedError());
        yield put(favoriteBrokerDealersActions.updateFavoriteBrokerDealerFailure(dealerId))
    }
}

function* addToFavorites(dealerId: number) {
    try {
        yield call(companiesService.addToFavorites, dealerId);
        const { favorites } = yield select((state: AppState) => state.favoriteBrokerDealers);
        const newFavorites = { ...favorites };
        newFavorites[dealerId] = true;
        yield put(favoriteBrokerDealersActions.updateFavoriteBrokerDealerSuccess(newFavorites, dealerId))
    } catch (e) {
        yield put(errorActions.unexpectedError());
        yield put(favoriteBrokerDealersActions.updateFavoriteBrokerDealerFailure(dealerId))
    }
}

function* watchUpdateFavoriteBrokerDealer(action: AnyAction) {
    const { dealerId, isFavorite } = action.payload;
    if (isFavorite) {
        yield deleteFromFavorites(dealerId);
    } else {
        yield addToFavorites(dealerId)
    }
}

function* watchLogout() {
    yield put(favoriteBrokerDealersActions.reset());
}
export function* watchFavoriteBrokerDealers() {
    yield takeEvery(getType(favoriteBrokerDealersActions.fetchFavoriteBrokerDealersRequest), watchFetchFavoriteBrokerDealers);
    yield takeEvery(getType(favoriteBrokerDealersActions.updateFavoriteBrokerDealerRequest), watchUpdateFavoriteBrokerDealer);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
