import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { emailPreferencesActions } from "../actions/email-preferences.actions";
import { errorActions } from "../actions/error.actions";
import { RequestState } from "../constants/request-state";
import { emailPreferencesService } from '../services/email-preferences.service';
import { EmailPreferences } from "../types/email-preferences/EmailPreferences";

function* watchRequest() {
    try {
        const preferences: EmailPreferences = yield call(emailPreferencesService.getEmailPreferences);
        yield put(emailPreferencesActions.requestResult(RequestState.success, preferences));
    } catch (e) {
        yield put(emailPreferencesActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

export function* watchEmailPreferences() {
    yield takeEvery(getType(emailPreferencesActions.request), watchRequest);
}
