import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { tradingService } from '../../services';
import { pushDataActions, roles } from '../../constants';
import { Trade } from '../../types/trades/Trade';
import { AppState } from '../../types/state/AppState';
import { user } from '../../user';
import { isRequestSuccess } from '../../utils';
import { awaitingTradesActions } from '../../actions/entities/awaitingTrades.actions';
import { AwaitingTrade } from '../../types/trades/AwaitingTrade';
import { TradeStatus } from '../../types/trades/TradeStatus';

function* watchTradesRequest() {
    try {
        const trades: AwaitingTrade[] = yield call(tradingService.getAwaitingActionTrades);
        yield put(awaitingTradesActions.requestTradesResult(RequestState.success, trades));
    } catch (e) {
        yield put(awaitingTradesActions.requestTradesResult(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchPushDataTrade(action: {type: string, trade: Trade}) {
    const { trade } = action;
    const tradesRequestState: RequestState = yield select((state: AppState) => state.entities.awaitingTrades.requestState);

    if(!isRequestSuccess(tradesRequestState) || !user.hasRoles(roles.SellerTrader, roles.BrokerDealerTrader)) {
        return;
    }

    // Do not increase pending trades badge counter if the trade was created by current user's company
    const isPendingTradeSeller =
        trade.status === TradeStatus.pending &&
        user.hasRoles(roles.SellerTrader) &&
        trade.sellerCompany &&
        trade.sellerCompany.id === user.current()?.companyId;

    if (!isPendingTradeSeller) {
        yield put(awaitingTradesActions.trade({
            id: String(trade.id),
            sellerSettlementStatus: trade.sellerSettlementStatus,
            buyerSettlementStatus: trade.buyerSettlementStatus,
            status: trade.status
        }));

    }
}

export function* watchAwaitingTrades() {
    yield takeEvery(getType(awaitingTradesActions.requestTrades), watchTradesRequest);
    yield takeEvery(pushDataActions.PUSH_DATA_TRADE, watchPushDataTrade);
}
