import { call, put, takeEvery } from "redux-saga/effects";
import { companiesService } from '../services/companies.service';
import { ActionType, getType } from "typesafe-actions";
import { companyMembersActions } from "../actions/company-members.actions";
import { CompanyMember } from "../types/company/CompanyMember";

function* watchFetchCompanyMembersRequest(action: ActionType<typeof companyMembersActions.fetchCompanyMembers.request>) {
    try {
        const members: CompanyMember[] = yield call(companiesService.getCompanyMembers, action.payload.companyId);
        yield put(companyMembersActions.fetchCompanyMembers.success({ companyId: action.payload.companyId, members }))
    } catch (e) {
        yield put(companyMembersActions.fetchCompanyMembers.failure({ companyId: action.payload.companyId, error: e }))
    }
}

export function* watchCompanyMembers() {
    yield takeEvery(getType(companyMembersActions.fetchCompanyMembers.request), watchFetchCompanyMembersRequest);
}