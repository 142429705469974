import { takeEvery, call, put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { ContactSalesRequest } from "../types/contact-sales/ContactSalesRequest";
import { contactSalesActions, errorActions } from '../actions';
import { contactSalesService } from "../services/contact-sales.service";

function* watchSendRequest(action: { type: string, payload: { request: ContactSalesRequest } }) {
    yield put(contactSalesActions.requestSendingFlag(true));

    try {
        yield call(contactSalesService.sendRequest, action.payload.request);
        yield put(contactSalesActions.hide());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(contactSalesActions.requestSendingFlag(false));
    }
}

export function* watchContactSales() {
    yield takeEvery(getType(contactSalesActions.send), watchSendRequest);
}
