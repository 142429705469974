import { ActionType, getType } from 'typesafe-actions';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { inventoryEditActions } from '../actions/inventory-edit.actions';
import { bdInventoryActions } from '../actions/bd-inventory.actions';
import { inventoryActions } from '../actions/inventory.actions';
import { inventoryService } from '../services/inventory.service';
import { errorActions } from '../actions';
import { accountActions } from '../constants';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';
import { AppState } from '../types/state/AppState';
import { SaveInventoryPosition } from '../types/inventory/SaveInventoryPosition';
import { GridDataItem } from '../types/state/GridState';
import { Rating } from '../types/enums/Rating';
import { InventoryFloatingIndex } from '../types/inventory/InventoryFloatingIndex';
import { EditingInventoryPosition } from '../types/state/InventoryEditState';
import { RequestState } from '../constants/request-state';
import { numericUtils } from '../utils';

function* watchSetTradedStatus(action: ActionType<typeof inventoryEditActions.setTradedStatus>) {
    const isUpdatingTradeStatus: {[key: number]: boolean} = yield select((s: AppState) => s.inventoryEdit.requestStateSetTradedStatus);
    const { inventoryPositionId, lockModifiedDate, tradeDate } = action.payload;

    if (isUpdatingTradeStatus[inventoryPositionId]) return;

    yield put(inventoryEditActions.setTradedRequestStatus(inventoryPositionId, true));

    try {
        yield call(inventoryService.setTraded, inventoryPositionId, lockModifiedDate, tradeDate);

        yield put(bdInventoryActions.inventoryStatusChange(inventoryPositionId, InventoryPositionStatus.traded));
        yield put(inventoryActions.inventoryStatusChange(inventoryPositionId, InventoryPositionStatus.traded));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(inventoryEditActions.setTradedRequestStatus(inventoryPositionId,false));
    }
}

function* watchSave(action: { type: string, payload: { companyId?: number, lockModifiedDate?: Date } }) {
    const isValid: boolean = yield select((s: AppState) => s.grid.isValid);
    const dataItems: GridDataItem<EditingInventoryPosition>[] = yield select((s: AppState) =>
        s.grid.dataItems.filter((i: GridDataItem<EditingInventoryPosition>) => !i.draft));

    if (isValid || !dataItems.length) {
        yield put(inventoryEditActions.setSavingState(RequestState.request));
        const positions: SaveInventoryPosition[] = dataItems.map(i => ({
            id: i.id,
            securityId: Number(i.securityId),
            isinCusip: String(i.isinCusip),
            ticker: String(i.ticker),
            rating: i.rating as Rating,
            bid: i.bid ? Number(i.bid) : undefined,
            offer: Number(i.offer),
            bidSize: i.bidSize ? Number(i.bidSize) * 1000000 : undefined,
            size: Number(i.size) * 1000000,
            bidDmBps: i.bidDmBps ? Number(i.bidDmBps) : undefined,
            offerDmBps: i.offerDmBps ? Number(i.offerDmBps) : undefined,
            creditEnhancement: i.creditEnhancement ? Number(i.creditEnhancement) : undefined,
            offerYield: i.offerYield ? Number(i.offerYield) : undefined,
            walYears: i.walYears ? Number(i.walYears) : undefined,
            description: i.description || undefined,
            floaterIndex: numericUtils.isNumber(i.floaterIndex) ? (Number(i.floaterIndex) as InventoryFloatingIndex) : undefined,
            mvoc: i.mvoc ? Number(i.mvoc) : undefined,
            spread: i.spread ? Number(i.spread) : undefined,
            manager: i.manager || undefined,
            ncEndMonth: i.ncEnd ? Number(i.ncEnd.split('/')[0]) : undefined,
            ncEndYear: i.ncEnd ? Number(i.ncEnd.split('/')[1]) : undefined,
            riEndMonth: i.riEnd ? Number(i.riEnd.split('/')[0]) : undefined,
            riEndYear: i.riEnd ? Number(i.riEnd.split('/')[1]) : undefined,
            dealLegalName: i.dealLegalName || undefined,
        }));

        try {
            yield call(inventoryService.save, positions, action.payload.lockModifiedDate, action.payload.companyId);
            yield put(inventoryEditActions.setSavingState(RequestState.success));
        } catch (e) {
            if (e.status === 409) {
                yield put(inventoryEditActions.setConflictsPopupVisible(true))
            } else {
                yield put(errorActions.unexpectedError(e));
            }
            yield put(inventoryEditActions.setSavingState(RequestState.failure));
        }
    }
}

function* watchLogout() {
    yield put(inventoryEditActions.reset());
}

export function* watchInventoryEdit() {
    yield takeLatest(getType(inventoryEditActions.setTradedStatus), watchSetTradedStatus);
    yield takeEvery(getType(inventoryEditActions.save), watchSave);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
