import { call, put, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { companiesActions, errorActions } from "../actions";
import { clearingBankActions } from "../actions/clearing-bank.actions";
import { constants } from '../constants';
import { RequestState } from "../constants/request-state";
import { clearingBankService } from "../services";
import { SettlementAgentCommissionByRating } from "../types/bid-as-dealer/SettlementAgentCommissionByRating";

function* watchSaveClearingBankSettings(action: ActionType<typeof clearingBankActions.saveClearingBankSettings>) {
    try {
        yield call(
            clearingBankService.saveClearingBankSettings,
            action.payload.companyId,
            action.payload.isClearingBank,
            action.payload.isClearingBank ? constants.defaultDailyTradingLimit : undefined,
            action.payload.commission
        );
        yield put(companiesActions.changeClearingBankSettings(
            action.payload.companyId,
            action.payload.isClearingBank)
        );
        yield put(clearingBankActions.settlementAgentCommissionResponse(
            RequestState.success,
            action.payload.companyId,
            action.payload.commission)
        );
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchRequestSettlementAgentCommission(action: ActionType<typeof clearingBankActions.settlementAgentCommissionRequest>) {
    try {
        const commission: SettlementAgentCommissionByRating =
            yield call(clearingBankService.getSettlementAgentCommission, action.payload.companyId);
        yield put(clearingBankActions.settlementAgentCommissionResponse(RequestState.success, action.payload.companyId, commission));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

export function* watchClearingBank() {
    yield takeEvery(getType(clearingBankActions.saveClearingBankSettings), watchSaveClearingBankSettings);
    yield takeEvery(getType(clearingBankActions.settlementAgentCommissionRequest), watchRequestSettlementAgentCommission);
}
