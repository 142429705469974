import { takeEvery, put, call, takeLatest, select } from 'redux-saga/effects';
import { accountActions } from '../constants';
import { dealerListActions } from '../actions/dealer-list.actions';
import { AppState } from '../types/state/AppState';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { DealerListEditableContact } from '../types/state/DealerListState';
import { companiesService, contactsService } from '../services';
import { errorActions } from '../actions';
import { ActionType } from '../types/ActionType';
import { brokerDealerContactListActions } from '../actions/broker-dealer-contact-list.actions';
import { brokerDealerListActions } from '../actions/broker-dealer-list.actions';
import { SaveContactModel } from '../types/company/Contact';

function* watchSaveContact(action: ActionType<{ contact: SaveContactModel }>) {
    const { contact } = action.payload;
    const editingState: DealerListEditableContact = yield select((state: AppState) => state.dealerList.editableContact);
    const brokerDealerList: BrokerDealerCompanySlim[] = yield select((state: AppState) => state.entities.brokerDealerList.items);
    const { dealerName } = editingState;
    const targetCompany = brokerDealerList.find(dealer =>
        dealer.name.localeCompare(dealerName, undefined, { sensitivity: 'accent' }) === 0);
    if (contact && targetCompany) {
        const requestData = {
            id: contact.id || undefined,
            companyId: targetCompany.id,
            firstName: contact.firstName.trim(),
            lastName: contact.lastName.trim(),
            email: contact.email.trim(),
            role: contact.role?.trim(),
            phone: contact.phone?.trim(),
            readonly: false
        };
        try {
            yield put(dealerListActions.editableContactSetRequestStatus(true));
            if (requestData.id) {
                yield call(contactsService.updateContact, requestData);
            } else {
                yield call(contactsService.addContact, requestData);
            }
            yield put(dealerListActions.resetEditableContact());
        } catch (e) {
            yield put(errorActions.unexpectedError(e))
        } finally {
            yield put(dealerListActions.editableContactSetRequestStatus(false));
        }
    }
}

function* watchDeleteContact(action: ActionType<{ contactId: number }>) {
    const { contactId } = action.payload;
    const editingState: DealerListEditableContact = yield select((state: AppState) => state.dealerList.editableContact);
    if (editingState.contact) {
        yield put(dealerListActions.resetEditableContact());
    }
    try {
        yield call(contactsService.deleteContact, contactId)
    } catch (e) {
        yield put(errorActions.error(e))
    }
}

function* watchContactInviteToJoin(action: ActionType<{ contactId: number }>) {
    const { contactId } = action.payload;
    yield put(dealerListActions.contactInviteToJoinSetRequestStatus(contactId, true));
    try {
        yield call(contactsService.sendInvite, contactId);
        yield put(brokerDealerContactListActions.setContactInvitationSent(contactId));
    } catch (e) {
        yield put(errorActions.error(e))
    } finally {
        yield put(dealerListActions.contactInviteToJoinSetRequestStatus(contactId, false));
    }
}

function* watchDistributionListInviteToJoin(action: ActionType<{ dealerId: number }>) {
    const { dealerId } = action.payload;
    try {
        yield call(companiesService.sendDistributionListInvite, dealerId);
        yield put(brokerDealerListActions.updateDealerDistributionListInvitationSent(dealerId))
    } catch (e) {
        yield put(errorActions.error(e))
    }
}

function* watchHeadOfTradingInviteToJoin(action: ActionType<{ dealerId: number }>) {
    const { dealerId } = action.payload;
    try {
        yield call(companiesService.sendHeadOfTrdingInvite, dealerId);
        yield put(brokerDealerListActions.updateDealerHeadOfTradingInvitationSent(dealerId))
    } catch (e) {
        yield put(errorActions.error(e))
    }
}

function* watchLogout() {
    yield put(dealerListActions.reset())
}

export function* watchDealerList() {
    yield takeLatest(dealerListActions.editableContactSave, watchSaveContact);
    yield takeLatest(dealerListActions.deleteContact, watchDeleteContact);
    yield takeLatest(dealerListActions.contactInviteToJoin, watchContactInviteToJoin);
    yield takeLatest(dealerListActions.distributionListInviteToJoin, watchDistributionListInviteToJoin);
    yield takeLatest(dealerListActions.headOfTradingInviteToJoin, watchHeadOfTradingInviteToJoin);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
