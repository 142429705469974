import { ActionType, getType } from 'typesafe-actions';
import { call, put, all, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { isNil, noop } from 'lodash';
import { RequestState } from '../constants/request-state';
import { PageConfigType } from '../types/page-config/PageConfigType';
import { AppState } from '../types/state/AppState';
import { InventorySearchParams, inventoryService } from '../services/inventory.service';
import { errorActions, searchSecuritiesActions } from '../actions';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { isRequesting, isRequestNone } from '../utils';
import { inventoryActions } from '../actions/inventory.actions';
import { IInventoryFilter } from '../types/state/InventoryListState';
import { AnyAction } from 'redux';
import { roles } from '../constants';
import {
    InventorySecurityOrderByColumn,
    InventorySecuritySortOrder
} from '../types/inventory/InventorySecurityOrderByColumn';
import { Rating } from '../types/enums/Rating';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import {
    amr, collateralManagers, collateralTypes, currentInventory, dateFrom, dateTo, esg, euCompliance, identifiers,
    inventoryStatuses, mixedDateRange, multipleCurrencies, multipleDealers, nonCallEndFrom, nonCallEndTo, ratings,
    reinvestmentEndFrom, reinvestmentEndTo, staticDeals, trustees, outOfNC, outOfRI
} from '../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../utils/filtering/query-string-serializer';
import { getInventoryPositionStatusByTitle } from '../components/inventory/inventory-position-status-title';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';
import { user } from '../user';
import { activeInventorySecurityActions } from '../actions/active-inventory-security.actions';
import { logger } from '../logging/logger';
import { Currency } from '../types/enums/Currency';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { inventoryFilters } from '../components/inventory/filter/inventoryFilters';
import { BwicFilterType, TFilter } from '../types/filters/FilterState';
import { createFilterActions } from '../actions/filter.actions';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { selectedDateOptions } from '../utils/amr-pipeline-filter.utils';
import { convertToApiCriteria, getDateOptionByRange, getYearsRange } from '../utils/filtering/filter.utils';
import { Company } from '../types/amr-pipeline/models/Company';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { DeserializeCommandHandler } from '../utils/filtering/serializers/DeserializeCommand';
import { InventorySearchResult } from '../types/inventory/InventorySearchResult';
import { history } from '../history';

const filterActions = createFilterActions(BwicFilterType.Inventory);

function* watchInit(action: ActionType<typeof inventoryActions.init>) {
    const { sortField, sortDirection } = action.payload;

    const dealers: BrokerDealerCompanySlim[] = yield select((state: AppState) => state.entities.brokerDealerList.items);
    const managers: Company[] = yield select((state: AppState) => state.entities.collateralManagers.items);

    const filtersConfig: UserConfigFilter[] = yield select((s: AppState) => s.entities.pageConfig.configs?.[PageConfigType.DealerInventory]?.filters);
    const canUseAdvancedFilters = user.hasFeatures(SubscriptionFeature.InventoryAvancedFilters);
    const defaultConfigFilter = canUseAdvancedFilters ? (filtersConfig || []).find(filter => filter.default) : undefined;

    const locationSearchString = history.location.search;
    const searchString = locationSearchString && locationSearchString.trim();
    let currencyOptions: Currency[] = [];
    let dealersIds: number[] = [];
    const isAdmin = user.hasRoles(roles.Administrator);

    const filter: TFilter<BwicFilterType.Inventory> = yield select((s: AppState) => s.filters.inventory.filter);
    // Call init after all dynamic filters (managers, broker-dealers) have already been loaded.
    yield put(filterActions.init({ ...filter, date: inventoryFilters.defaultFilter.date }));

    if (searchString) {
        const [ncEndSerializers, getNcEndDeserializeResult] = mixedDateRange('nonCallEndFrom', 'nonCallEndTo');
        const [riEndSerializers, getRiEndDeserializeResult] = mixedDateRange('reinvestmentEndFrom', 'reinvestmentEndTo');

        let from: Date | undefined | null = undefined;
        let to: Date | undefined | null = undefined;

        let identifiersList: string[] = [];
        let statusOptions: (InventoryPositionStatus | undefined)[] = [];
        let ratingsOptions: Rating[] = [];
        let collateralTypeOptions: CollateralType[] = [];
        let collateralManagersReferenceNames: string[] = [];
        let isEsg: boolean | undefined = undefined;
        let isEuCompilance: boolean | undefined = undefined;
        let isStaticDeals: boolean | undefined = undefined;
        let maturityFrom: Date | null = null;
        let maturityTo: Date | null = null;
        let vintageFrom: Date | null = null;
        let vintageTo: Date | null = null;
        let closingFrom: Date | null = null;
        let closingTo: Date | null = null;
        let trusteeValues: string[] = [];
        let amrValue: boolean | undefined = undefined;
        let isOutOfNC: boolean | undefined = undefined;
        let isOutofRI: boolean | undefined = undefined;

        const serializers: QueryStringArgumentSerializer<any>[] = [
            inventoryStatuses(parsedStatuses => statusOptions = parsedStatuses.map(s => getInventoryPositionStatusByTitle(s))),
            ratings(parsedRatings => ratingsOptions = parsedRatings.map(r => Rating[r as keyof typeof Rating])),
            identifiers(list => identifiersList = list),
            multipleCurrencies(parsedCurrency => currencyOptions = parsedCurrency.map(c => Currency[c as keyof typeof Currency])),
            multipleDealers(dealers.map(d => String(d.id)), parsedDealers => dealersIds = parsedDealers.map(d => Number(d))),
            collateralTypes(parsedCollateralTypes => collateralTypeOptions = parsedCollateralTypes as CollateralType[]),
            collateralManagers(values => collateralManagersReferenceNames = values, managers.map(m => m.referenceName)),
            esg(value => isEsg = value),
            euCompliance(parsedEuCompilance => isEuCompilance = parsedEuCompilance),
            staticDeals(parsedStaticDeals => isStaticDeals = parsedStaticDeals),
            outOfNC(value => isOutOfNC = value),
            outOfRI(value => isOutofRI = value),
            ...ncEndSerializers,
            ...riEndSerializers,
            dateFrom(value => maturityFrom = value, 'maturityFrom'),
            dateTo(value => maturityTo = value, 'maturityTo'),
            dateFrom(value => vintageFrom = value, 'vintageFrom'),
            dateTo(value => vintageTo = value, 'vintageTo'),
            dateFrom(value => closingFrom = value, 'closingFrom'),
            dateTo(value => closingTo = value, 'closingTo'),
            trustees(values => trusteeValues = values),
            amr(value => amrValue = value),
        ];

        if (isAdmin) {
            serializers.push(
                dateFrom(parsedDate => from = parsedDate),
                dateTo(parsedDate => to = parsedDate),
            );
        }

        queryStringSerializer.deserialize(searchString, serializers);

        yield put(filterActions.resetFilter());

        // Set nonCallEnd and reinvestmentEnd values
        const ncEndDeserializeResult = getNcEndDeserializeResult();
        const riEndDeserializeResult = getRiEndDeserializeResult();

        if (isAdmin) {
            const dateOption = getDateOptionByRange(dateRangeFilterOptions.CurrentInventory, from, to);

            if (dateOption.key !== dateRangeFilterOptions.All.key) {
                yield put(filterActions.filterDateSelectOption(dateOption, 'date'));
            }
            if (from || to) {
                yield put(filterActions.filterDateSelectCustomRange({ from, to }, 'date'));
            }
        }

        const statusesCommand = {
            execute: function* () {
                yield all(statusOptions.map((s: InventoryPositionStatus | undefined) => {
                    const statusOption = inventoryFilters.defaultFilter.statuses.filter.filter(status => Number(status.value) === s)[0];
                    return put(filterActions.filterSelectChange(statusOption.value, 'statuses'));
                }));
            }
        }

        const identifiersCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!identifiersList.length,
            execute: function* () {
                yield all(identifiersList.map(i => put(searchSecuritiesActions.addSearchItem(i))));
            }
        }

        const rating = {
            execute: function* () {
                yield all(ratingsOptions.map((r: Rating) => {
                    const ratingOption = inventoryFilters.defaultFilter.ratings.filter.filter(rating => String(rating.value) === r)[0];
                    return put(filterActions.filterSelectChange(ratingOption.value, 'ratings'))
                }));
            }
        };

        const collateralTypesCommand = {
            shouldExecute: () => !!collateralTypeOptions.length,
            execute: function* () {
                yield all(collateralTypeOptions.map((collateralTypeOption: CollateralType) => put(filterActions.filterSelectChange(collateralTypeOption, 'collateralType'))));
            }
        }

        const esgCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEsg),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('esg'));
                yield put(filterActions.filterRadioChange(isEsg!, 'esg'));
            }
        }

        const euCompilanceCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEuCompilance),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('euCompliance'));
                yield put(filterActions.filterRadioChange(isEuCompilance!, 'euCompliance'));
            }
        }

        const staticDealsCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isStaticDeals),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('staticDeal'));
                yield put(filterActions.filterRadioChange(isStaticDeals!, 'staticDeal'));
            }
        }

        const outOfNCCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutOfNC),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfNC'));
                yield put(filterActions.filterRadioChange(isOutOfNC!, 'outOfNC'));
            }
        }

        const outofRICommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutofRI),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfRI'));
                yield put(filterActions.filterRadioChange(isOutofRI!, 'outOfRI'));
            }
        }

        const nonCallEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!ncEndDeserializeResult?.dates || !!ncEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('nonCallEnd', ncEndDeserializeResult))
            }
        };
        const reinvestmentEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!riEndDeserializeResult?.dates || !!riEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('reinvestmentEnd', riEndDeserializeResult));
            }
        };

        const currenciesCommand = {
            shouldExecute: () => !!currencyOptions.length,
            execute: function* () {
                yield all(currencyOptions.map(
                    c =>
                        put(filterActions.filterSelectChange(c.toString(), 'currency'))
                ));
            }
        }

        const collateralManagersReferenceNamesCommand = {
            shouldExecute: () => !!collateralManagersReferenceNames.length,
            execute: function* () {
                yield all(collateralManagersReferenceNames.map((cm: string) => {
                    return put(filterActions.filterSelectChange(cm, 'managers'));
                }));
            }
        }

        const dealerIdsCommand = {
            shouldExecute: () => !!dealersIds.length,
            execute: function* () {
                yield all(dealersIds.map((d: number) => {
                    return put(filterActions.filterSelectChange(d, 'dealerIds'));
                }));
            }
        }

        const trusteesValuesCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!trusteeValues.length,
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('trustees'));
                yield all(trusteeValues.map(name => put(filterActions.filterSelectChange(name, 'trustees'))));
            }
        }

        const maturityCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(maturityFrom) || !isNil(maturityTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('maturity'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, maturityFrom, maturityTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'maturity'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: maturityFrom, to: maturityTo }, 'maturity'),
                );
            }
        }

        const vintageCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(vintageFrom) || !isNil(vintageTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('vintage'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, vintageFrom, vintageTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'vintage'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: vintageFrom, to: vintageTo }, 'vintage'),
                );
            }
        }

        const closingCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(closingFrom) || !isNil(closingTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('closing'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, closingFrom, closingTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'closing'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: closingFrom, to: closingTo }, 'closing'),
                );
            }
        }

        const amrValueCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(amrValue),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('amr'));
                yield put(filterActions.filterRadioChange(amrValue!, 'amr'));
            }
        }

        const isAllCommandsExecuted: boolean =
            yield new DeserializeCommandHandler()
                .addCommand(statusesCommand)
                .addCommand(identifiersCommand)
                .addCommand(rating)
                .addCommand(collateralTypesCommand)
                .addCommand(esgCommand)
                .addCommand(euCompilanceCommand)
                .addCommand(staticDealsCommand)
                .addCommand(outOfNCCommand)
                .addCommand(outofRICommand)
                .addCommand(currenciesCommand)
                .addCommand(nonCallEndCommand)
                .addCommand(reinvestmentEndCommand)
                .addCommand(collateralManagersReferenceNamesCommand)
                .addCommand(dealerIdsCommand)
                .addCommand(trusteesValuesCommand)
                .addCommand(maturityCommand)
                .addCommand(vintageCommand)
                .addCommand(closingCommand)
                .addCommand(amrValueCommand)
                .processGenerator()

        if (!isAllCommandsExecuted) {
            yield put(inventoryActions.advancedFilterBlocked(true));
            return;
        }



    } else if (defaultConfigFilter) {
        yield put(filterActions.selectFilterFromConfig(defaultConfigFilter));
    } else {
        if (isAdmin) {
            yield put(filterActions.filterDateSelectOption(dateRangeFilterOptions.CurrentInventory, 'date'));
        }
    }
    yield put(inventoryActions.requestInventoryExistFlag());
    yield put(inventoryActions.loadSellerInventory(sortField, sortDirection, true));
}

function* watchRequestInventoryExist() {
    try {
        const exist: boolean = yield call(inventoryService.getInventoryExistFlag);
        yield put(inventoryActions.inventoryExistFlag(exist));
    } catch (e) {
        yield put(inventoryActions.inventoryExistFlag(true));
    }
}

function getFilterParams(filter: IInventoryFilter) {
    const defaultDateRange = { dateFrom: undefined, dateTo: undefined };

    const ratings = filter.ratings.filter.filter(r => r.selected).map(r => String(r.value));
    const statuses = filter.statuses.filter.filter(f => f.selected).map(f => Number(f.value));
    const selectedCurrencies = filter.currency.filter.filter(c => c.selected).map(c => c.value);
    const currency = selectedCurrencies.length && selectedCurrencies.length === 1 ? selectedCurrencies[0] : undefined
    const collateralTypes = filter.collateralType.filter.filter(c => c.selected).map(c => c.value);
    const collateralManagers = filter.managers.filter.filter(c => c.selected).map(c => c.value);

    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const nonCallEndDates = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);
    const reinvestmentEndDates = selectedDateOptions(filter.reinvestmentEnd);

    const maturityRange = filter.maturity.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : defaultDateRange;
    const vintageRange = filter.vintage.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : defaultDateRange;
    const closingRange = filter.closing.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : defaultDateRange;

    const esg = filter.esg.filter.selectedOption;
    const euCompliance = filter.euCompliance.filter.selectedOption;
    const staticDeals = filter.staticDeal.filter.selectedOption;
    const outOfNC = filter.outOfNC.filter.selectedOption;
    const outOfRI = filter.outOfRI.filter.selectedOption;

    return {
        ratings,
        statuses,
        currency,
        collateralTypes,
        collateralManagers,
        esg,
        staticDeals,
        euCompliance,
        nonCallEndFromYear: nonCallEndYears?.from,
        nonCallEndToYear: nonCallEndYears?.to,
        nonCallEndFromDate: nonCallEndDates?.from,
        nonCallEndToDate: nonCallEndDates?.to,
        reinvestmentEndFromYear: reinvestmentEndYears?.from,
        reinvestmentEndToYear: reinvestmentEndYears?.to,
        reinvestmentEndFromDate: reinvestmentEndDates?.from,
        reinvestmentEndToDate: reinvestmentEndDates?.to,
        maturityFrom: maturityRange.dateFrom,
        maturityTo: maturityRange.dateTo,
        vintageFrom: vintageRange.dateFrom,
        vintageTo: vintageRange.dateTo,
        closingFrom: closingRange.dateFrom,
        closingTo: closingRange.dateTo,
        trustees: filter.trustees?.filter.filter(f => f.selected).map(f => f.value),
        amr: filter.amr?.filter.selectedOption,
        outOfNC,
        outOfRI
    };
}

function getSearchParams(
    filter: IInventoryFilter,
    searchSecurities: string[],
    sortField: InventorySecurityOrderByColumn,
    sortDirection: InventorySecuritySortOrder,
    currentPageNumber?: number,
    pageSize?: number
): InventorySearchParams {

    const {
        ratings,
        statuses,
        currency,
        collateralTypes,
        collateralManagers,
        staticDeals,
        esg,
        euCompliance,
        nonCallEndFromYear,
        nonCallEndToYear,
        nonCallEndFromDate,
        nonCallEndToDate,
        reinvestmentEndFromYear,
        reinvestmentEndToYear,
        reinvestmentEndFromDate,
        reinvestmentEndToDate,
        maturityFrom,
        maturityTo,
        vintageFrom,
        vintageTo,
        closingFrom,
        closingTo,
        trustees,
        amr,
        outOfNC,
        outOfRI
    } = getFilterParams(filter);

    const searchParams = {
        identifiers: searchSecurities,
        statuses,
        ratings,
        currency,
        dealers: filter.dealerIds.filter.filter(el => el.selected).map(el => el.value),
        collateralTypes,
        collateralManagers,
        esg,
        euCompliance,
        staticDeals,
        nonCallEndFromYear,
        nonCallEndToYear,
        nonCallEndFromDate,
        nonCallEndToDate,
        reinvestmentEndFromYear,
        reinvestmentEndToYear,
        reinvestmentEndFromDate,
        reinvestmentEndToDate,
        maturityFrom,
        maturityTo,
        vintageFrom,
        vintageTo,
        closingFrom,
        closingTo,
        trustees,
        amr,
        orderByColumn: sortField,
        sortOrder: sortDirection,
        page: currentPageNumber || undefined,
        pageSize: pageSize || undefined,
        outOfNC,
        outOfRI
    };

    if (user.hasRoles(roles.Administrator, roles.DataEntry) && filter.date.filter.selectedOption) {
        const dateParams = convertToApiCriteria(filter.date.filter.selectedOption, filter.date.filter.options);
        return {
            ...searchParams,
            ...dateParams
        };
    }

    return searchParams;
}

function* watchLoadInventory(action: ActionType<typeof inventoryActions.loadSellerInventory>) {
    const { sortField, sortDirection, applyFilter, loadSummary } = action.payload;
    const {
        currentPageNumber,
        requestState,
        pageSize
    } = yield select((state: AppState) => state.entities.allInventory);
    const filter: IInventoryFilter = yield select((state: AppState) => state.filters.inventory.filter);
    const lastAppliedFilter: IInventoryFilter = yield select((state: AppState) => state.filters.inventory.lastAppliedFilter);
    const searchSecurities: string[] = yield select((state: AppState) => state.searchSecurities.searchTermItems);
    const filterBlocked: boolean = yield select((state: AppState) => state.inventory.advancedFilterBlocked);

    if(loadSummary) {
        put(inventoryActions.resetSummary());
    }

    if (filterBlocked) return;

    if (!isRequesting(requestState)) {
        const searchFilter = applyFilter ? filter: lastAppliedFilter;
        yield put(inventoryActions.request());
        const searchParams = getSearchParams(
            searchFilter,
            searchSecurities,
            sortField,
            sortDirection,
            currentPageNumber,
            pageSize
        );
        try {
            const { result, totalRecordNumber, ...summary }: InventorySearchResult = yield call(inventoryService.getAllInventory, { ...searchParams, loadSummary: action.payload.loadSummary });
            yield put(inventoryActions.requestResult(RequestState.success, result, totalRecordNumber, loadSummary ? summary: undefined));
            if(applyFilter) {
                yield put(filterActions.applyFilter());
            }
            yield call(watchRedirectWithFilterArguments);
        } catch (e) {
            yield put(errorActions.criticalError(e));
        }
    }
}

function* watchRedirectWithFilterArguments() {
    const dealers: BrokerDealerCompanySlim[] = yield select((state: AppState) => state.entities.brokerDealerList.items)
    const filter: IInventoryFilter = yield select((state: AppState) => state.filters.inventory.filter);
    const { searchTermItems } = yield select((state: AppState) => state.searchSecurities);
    const criteria = getFilterCriteria(filter, searchTermItems);
    const serializers: QueryStringArgumentSerializer<any>[] = [
        identifiers(), inventoryStatuses(), ratings(), multipleCurrencies(), multipleDealers(dealers.map(d => String(d.id))),
        collateralTypes(), collateralManagers(), esg(), euCompliance(), staticDeals(), nonCallEndFrom(),
        nonCallEndTo(), reinvestmentEndFrom(), reinvestmentEndTo(), currentInventory(), trustees(), amr(),
        dateFrom(noop, 'maturityFrom'), dateTo(noop, 'maturityTo'), dateFrom(noop, 'vintageFrom'),
        dateTo(noop, 'vintageTo'), dateFrom(noop, 'closingFrom'), dateTo(noop, 'closingTo'), outOfNC(), outOfRI()
    ];

    if (user.hasRoles(roles.Administrator)) {
        serializers.push(dateFrom(), dateTo());
    }

    const queryString = queryStringSerializer.serialize(criteria, serializers);
    if (queryString) {
        yield call(history.replace ,history.location.pathname + '?' + queryString);
    } else if (history.location.search) {
        yield call(history.replace, history.location.pathname);
    }
}

function getFilterCriteria(filter: IInventoryFilter, isinCusipsAndTickers: string[]) {
    const defaultDateRange = { dateFrom: undefined, dateTo: undefined };

    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);

    const nonCallEndDateRange = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndDateRange = selectedDateOptions(filter.reinvestmentEnd);

    const maturityRange = filter.maturity.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : defaultDateRange;
    const vintageRange = filter.vintage.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : defaultDateRange;
    const closingRange = filter.closing.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : defaultDateRange;

    const criteria = {
        isinCusipsAndTickers,
        statuses: filter.statuses.filter.filter(s => s.selected).map(s => s.text),
        currency: filter.currency.filter.filter(c => c.selected).map(c => c.value),
        dealers: filter.dealerIds.filter.filter(d => d.selected).map(d => d.value),
        ratings: filter.ratings.filter.filter(r => r.selected).map(r => r.value),
        collateralTypes: filter.collateralType.filter.filter(r => r.selected).map(r => r.value),
        collateralManagers: filter.managers.filter.filter(c => c.selected).map(c => c.value),
        nonCallEndFrom: nonCallEndYears.from ?? nonCallEndDateRange.from,
        nonCallEndTo: nonCallEndYears.to ?? nonCallEndDateRange.to,
        reinvestmentEndFrom: reinvestmentEndYears.from ?? reinvestmentEndDateRange.from,
        reinvestmentEndTo: reinvestmentEndYears.to ?? reinvestmentEndDateRange.to,
        esg: filter.esg.filter.selectedOption,
        euCompliance: filter.euCompliance.filter.selectedOption,
        staticDeals: filter.staticDeal.filter.selectedOption,
        maturityFrom: maturityRange.dateFrom,
        maturityTo: maturityRange.dateTo,
        vintageFrom: vintageRange.dateFrom,
        vintageTo: vintageRange.dateTo,
        closingFrom: closingRange.dateFrom,
        closingTo: closingRange.dateTo,
        trustees: filter.trustees?.filter.filter(f => f.selected).map(f => f.value),
        amr: filter.amr?.filter.selectedOption,
        outOfNC: filter.outOfNC?.filter.selectedOption,
        outOfRI: filter.outOfRI?.filter.selectedOption,
    };


    return user.hasRoles(roles.Administrator) && filter.date.filter.selectedOption
        ? { ...criteria, ...(convertToApiCriteria(filter.date.filter.selectedOption, filter.date.filter.options)) }
        : criteria;
}

function* watchExportInventoryRequest(action: AnyAction) {
    const { sortField, sortDirection } = action.payload;

    const appliedFilter: IInventoryFilter = yield select((state: AppState) => state.filters.inventory.lastAppliedFilter);
    const searchSecurities: string[] = yield select((state: AppState) => state.searchSecurities.searchTermItems);

    const searchParams = getSearchParams(appliedFilter, searchSecurities, sortField, sortDirection);
    searchParams.orderByColumn = Number(InventorySecurityOrderByColumn[sortField]) || undefined;
    searchParams.sortOrder = Number(InventorySecuritySortOrder[sortDirection]) || undefined;

    try {
        const file: { blob: Blob, name: string } = yield call(inventoryService.exportInventoryList, searchParams);
        saveAs(file.blob, file.name);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(inventoryActions.exportInventoryResponse());
    }
}

function* watchPushInventory() {
    const isPageActive: boolean = yield select((s: AppState) => !isRequestNone(s.inventory.requestStateInventoryExist));

    if (isPageActive) {
        yield put(inventoryActions.requestInventoryExistFlag());
    }
}

function* watchActiveInventorySecurityIds() {
    try {
        const activeSecurityIds: number[] = yield call(inventoryService.getActiveSecurityIdList);
        yield put(activeInventorySecurityActions.requestActiveInventorySecuritiesSuccess(activeSecurityIds));
    } catch (e) {
        yield put(activeInventorySecurityActions.requestActiveInventorySecuritiesError());
        logger.exception(e as Error, 'Failed to request active inventory security id by count');
    }
}

export function* watchInventory() {
    yield takeLatest(getType(inventoryActions.init), watchInit);
    yield takeLatest(getType(inventoryActions.loadSellerInventory), watchLoadInventory);
    yield takeLatest(getType(inventoryActions.requestInventoryExistFlag), watchRequestInventoryExist);
    yield takeEvery(getType(inventoryActions.pushInventory), watchPushInventory);
    yield takeLatest(getType(inventoryActions.exportInventoryRequest), watchExportInventoryRequest);
    yield takeEvery(getType(activeInventorySecurityActions.requestActiveInventorySecurities), watchActiveInventorySecurityIds);
}
