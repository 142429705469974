import { getType } from 'typesafe-actions';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { companyDetailsActions } from '../actions/company-details.actions';
import { companiesService } from '../services';
import { errorActions } from '../actions';
import { CompanySlim } from '../types/company/CompanySlim';
import { AppState } from '../types/state/AppState';
import { ActionType } from '../types/ActionType';
import { Company } from '../types/company/Company';
import { accountActions } from '../constants';

function* watchSetVisible(action: ActionType<{ company: CompanySlim }>) {
    const { company } = action.payload;
    const isCompanyExist: CompanySlim = yield select((state: AppState) => state.companyDetails.companies[company?.id]);
    if (company && !isCompanyExist) {
        yield put(companyDetailsActions.fetchCompanyDetailsRequest(company.id, company.referenceName))
    }
}

function* watchFetchCompanyDetailsRequest(action: ActionType<{ companyId: number, companyReferenceName: string }>) {
    const { companyId, companyReferenceName } = action.payload;
    try {
        const company: Company = yield call(companiesService.getCompany, companyReferenceName);
        yield put(companyDetailsActions.setCompanyDetails(companyId, company));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(companyDetailsActions.fetchCompanyDetailsResponse(companyId));
    }
}

function* watchLogout() {
    yield put(companyDetailsActions.reset())
}

export function* watchCompanyDetails() {
    yield takeLatest(getType(companyDetailsActions.show), watchSetVisible);
    yield takeLatest(getType(companyDetailsActions.fetchCompanyDetailsRequest), watchFetchCompanyDetailsRequest);
    yield takeLatest(accountActions.LOGOUT, watchLogout);
}
