import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { rejectedTradeActions } from '../../actions/entities/rejected-trades.actions';
import { errorActions } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { tradeReportingService } from '../../services/tradeReportingService';
import { RejectedTrade } from '../../types/trades/RejectedTrade';

function* watchRequestRejectedTrades() {
    try {
        const trades: RejectedTrade[] = yield call(tradeReportingService.getResolveTrades);
        yield put(rejectedTradeActions.requestResult(RequestState.success, trades));
    } catch (e) {
        yield put(rejectedTradeActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

export function* watchRejectedTrades() {
    yield takeEvery(getType(rejectedTradeActions.request), watchRequestRejectedTrades);
}
