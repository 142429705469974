import { call, put, takeLatest } from 'redux-saga/effects';
import { settlementAgentAgreementService, currencyService } from '../services';
import { clientsCompaniesActions } from '../actions/clients-companies.actions';
import { errorActions } from '../actions';
import { settlementAgentAgeementPushActions } from '../actions/settlement-agent-agreement-push.actions';
import { user } from '../user';
import { accountActions, roles } from '../constants';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { logger } from '../logging/logger';
import { SettlementAgentClientAgreement } from '../types/bid-as-dealer/SettlementAgentClientAgreement';
import { companyDetailsActions } from '../actions/company-details.actions';
import { CurrencyRate } from '../types/currency/CurrencyRate';
import { RequestState } from '../constants/request-state';
import { ActionType } from '../types/ActionType';

function* watchCompaniesInit() {
    try {
        const agreements: SettlementAgentClientAgreement[] = yield call(settlementAgentAgreementService.getAgreementForSettlementAgent);
        const activeAgreements = agreements.filter(c => c.signatory.status === CompanyStatus.active);
        yield put(clientsCompaniesActions.fetchListSuccess(activeAgreements));
    } catch (e) {
        yield put(clientsCompaniesActions.fetchListFailure());
        yield put(errorActions.criticalError(e));
    }
}

function* watchUpdateRequestStatus(action: ReturnType<typeof clientsCompaniesActions.updateAgreementRequest>) {
    try {
        yield call(settlementAgentAgreementService.updateAgreement, action.payload.requestData);
        yield put(clientsCompaniesActions.updateAgreementSuccess(action.payload.requestData.bidderId));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(clientsCompaniesActions.updateAgreementFailure(action.payload.requestData.bidderId));
    }
}

function* watchFetchPendingAgreementsRequest() {
    try {
        const pendingCount: number = yield call(settlementAgentAgreementService.fetchPendingAgreementCount);
        yield put(clientsCompaniesActions.fetchPendingAgreementsCountSuccess(pendingCount));
    } catch (e) {
        logger.exception(e, 'Failed to fetch the number of pending bid as dealer agreements');
    }
}

function* watchHideActivePanels() {
    yield put(clientsCompaniesActions.resetSelectedAgreement());
    yield put(companyDetailsActions.hide());
}

function* watchSettlementAgentAgreementPushReceived() {
    if (user.hasRoles(...roles.bd())) {
        yield put(clientsCompaniesActions.fetchPendingAgreementCountRequest());
    }
}

function* watchGetCurrencyRate() {
    try {
        const rate: CurrencyRate[] = yield call(currencyService.getCurrencyRate);
        yield put(clientsCompaniesActions.setResultGetCurrencyRate(RequestState.success, rate));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(clientsCompaniesActions.setResultGetCurrencyRate(RequestState.failure, []));
    }
}

function* watchUpdateCurrencyRate(action: ActionType<{ rate: CurrencyRate }>) {
    const { rate } = action.payload;
    try {
        yield call(currencyService.setCurrencyRate, rate.usdRate);
        yield put(clientsCompaniesActions.setResultUpdateCurrencyRate(RequestState.success, rate));
        yield put(clientsCompaniesActions.setVisibleUpdateRatePopup(false));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(clientsCompaniesActions.setResultUpdateCurrencyRate(RequestState.failure, rate));
    }
}

function* watchLogout() {
    yield put(clientsCompaniesActions.reset());
}

export function* watchClientCompanies() {
    yield takeLatest(clientsCompaniesActions.fetchListRequest, watchCompaniesInit);
    yield takeLatest(clientsCompaniesActions.fetchPendingAgreementCountRequest, watchFetchPendingAgreementsRequest);
    yield takeLatest(clientsCompaniesActions.updateAgreementRequest, watchUpdateRequestStatus);
    yield takeLatest(settlementAgentAgeementPushActions.settlementAgentAgreementPushReceived, watchSettlementAgentAgreementPushReceived);
    yield takeLatest(clientsCompaniesActions.hideActivePanels, watchHideActivePanels);
    yield takeLatest(clientsCompaniesActions.getCurrencyRate, watchGetCurrencyRate);
    yield takeLatest(clientsCompaniesActions.updateCurrencyRate, watchUpdateCurrencyRate);
    yield takeLatest(accountActions.LOGOUT, watchLogout)
}
