import { put, select, takeEvery } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import { dealerListPanelActions, pushBwicActions } from "../actions";
import { getBuyers } from "../selectors";
import { JumpBallStage2Participants } from "../types/bwic-process/JumpBallSettings";
import { TopXStage2Participants } from "../types/bwic-process/TopXSettings";
import { BwicPosition } from "../types/bwic/BwicPosition";
import { BidderCompanySlim } from "../types/company/BidderCompanySlim";
import { CompanySlim } from "../types/company/CompanySlim";
import { OpenBiddingImproverCount } from "../types/enums/OpenBiddingImproverCount";
import { OpenBiddingStatus } from "../types/enums/OpenBiddingStatus";
import { BwicProcessType, Process } from "../types/models/Process";
import { AppState } from "../types/state/AppState";
import { DealerListPanelState } from "../types/state/DealerListPanelState";
import { apiUtils, biddingUtils } from "../utils";
import { calculateStage2Participants } from "../utils/stage2-participants-calculator";

function* watchNewBid(action: ActionType<typeof pushBwicActions.newBid>) {
    const bwicReferenceName: string = yield select((s: AppState) => s.bidding.bwic?.referenceName);
    const positions: BwicPosition[] = yield select((s: AppState) => s.sellerBidding.securities);
    const { companyHiddenState, isStage2ParticipantsOnly }: DealerListPanelState = yield select((s: AppState) => s.dealerListPanel);


    if (!bwicReferenceName ||
        !positions?.length ||
        !action.bid ||
        !action.bid.company) {
        return;
    }

    if (isStage2ParticipantsOnly) {
        yield updateStage2ParticipantsOnly();
    } else if (companyHiddenState[biddingUtils.getBidCompanyIdentifier(action.bid)]) {
        const allBids = positions.map(p => p.bids || []).flat();
        const companyBidsLength = allBids.filter(b => biddingUtils.getBidCompanyIdentifier(b) === biddingUtils.getBidCompanyIdentifier(action.bid)).length;

        if (companyBidsLength === 1) {
            // Show company column if it sent the first bid
            yield put(dealerListPanelActions.toggleCompanyVisible(biddingUtils.getBidCompanyIdentifier(action.bid)));
        }
    }
}

function* watchStage2ParticipantsFlagChange(action: ActionType<typeof dealerListPanelActions.stage2ParticipantsFlagChange>) {
    if (action.payload.isStage2participantsOnly) {
        yield updateStage2ParticipantsOnly();
    } else if (action.payload.showAllEnabled) {
        yield put(dealerListPanelActions.showBwicParticipantsOnly());
    }
}

function* updateStage2ParticipantsOnly() {
    const stagedBiddingStatus: OpenBiddingStatus = yield select((s: AppState) => s.bidding.bwic?.process.stagedBiddingStatus);
    const positions: BwicPosition[] = yield select((s: AppState) => s.sellerBidding.securities);
    const bwicProcess: Process = yield select((s: AppState) => s.bidding.bwic?.process);
    const improverCount: JumpBallStage2Participants | TopXStage2Participants | OpenBiddingImproverCount | undefined =
        yield select((s: AppState) => {
            switch (s.bidding.bwic?.process.type) {
                case BwicProcessType.JumpBall: return s.bidding.bwic?.process.jumpBall?.improverCount
                case BwicProcessType.TopX: return s.bidding.bwic?.process.topX?.improverCount
                case BwicProcessType.Live: return s.bidding.bwic?.process.liveBidding?.openBiddingImproverCount
                default: return undefined
            }
        });

    const bwicParticipants: CompanySlim[] = yield select((s: AppState) => s.bidding.bwic?.companies) ?? [];
    const offPlatformCompanies: CompanySlim[] = yield select((s: AppState) => s.bidOnBehalf.companies);
    const brokerDealers: BidderCompanySlim[] = [...bwicParticipants, ...offPlatformCompanies]
        .map(company => ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) }));
    const buyers = getBuyers(positions ?? []);
    const companies = [...brokerDealers, ...buyers];

    if (positions?.length && bwicProcess != null && improverCount && companies.length) {
        let stage2Participants: { [identifier: string]: boolean } = {};
        let participantsWithBids: { [identifier: string]: boolean } = {};
        positions.forEach(p => {
            const notPassBids = p.bids?.filter(b => !b.pass) ?? [];

            stage2Participants = {
                ...stage2Participants,
                ...calculateStage2Participants(notPassBids, bwicProcess)
            };

            participantsWithBids = {
                ...participantsWithBids,
                ...apiUtils.normalize(notPassBids, bid => biddingUtils.getBidCompanyIdentifier(bid), () => true)
            };
        })

        const companiesToHide = companies.filter(c => !stage2Participants[c.identifier]);
        yield put(dealerListPanelActions.setDefaultVisibility(companiesToHide));

        if (
            stagedBiddingStatus === OpenBiddingStatus.stage1Ended ||
            stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded
        ) {
            const companiesWithoutBids = companies.filter(c => !participantsWithBids[c.identifier]);
            yield put(dealerListPanelActions.setDisabledCompanies(companiesWithoutBids));
        }
    }
}

function* watchParticipantsOnlyVisible() {
    const participants: CompanySlim[] = yield select(s => s.bidding.bwic.companies || []);
    const offPlatformCompanies: CompanySlim[] = yield select(s => s.bidOnBehalf.companies || []);
    const companiesToHide = offPlatformCompanies
        .filter(c => !participants.some(p => p.id === c.id))
        .map(company => ({ ...company, identifier: biddingUtils.getBidCompanyIdentifier({ company }) }));

    yield put(dealerListPanelActions.setDefaultVisibility(companiesToHide));
}

function* watchStartStage2(action: ActionType<typeof pushBwicActions.stagedBiddingStartStage2>) {
    const bwicReferenceName: string = yield select((s: AppState) => s.bidding.bwic?.referenceName);
    const isCurrentBiddingBwicChanged = bwicReferenceName === action.bwicReferenceName;

    if (!isCurrentBiddingBwicChanged) {
        return;
    }

    const isStage2ParticipantsOnly: boolean = yield select((s: AppState) => s.dealerListPanel.isStage2ParticipantsOnly);
    const process: Process = yield select((s: AppState) => s.bidding.bwic?.process);

    if (!isStage2ParticipantsOnly && (
        process?.type === BwicProcessType.JumpBall ||
        process?.type === BwicProcessType.TopX ||
        process?.type === BwicProcessType.Live
    )) {
        yield put(dealerListPanelActions.stage2ParticipantsFlagChange(true));
    }
}

export function* watchDealerListPanel() {
    yield takeEvery(getType(pushBwicActions.newBid), watchNewBid);
    yield takeEvery(getType(dealerListPanelActions.stage2ParticipantsFlagChange), watchStage2ParticipantsFlagChange);
    yield takeEvery(getType(dealerListPanelActions.showBwicParticipantsOnly), watchParticipantsOnlyVisible)
    yield takeEvery(getType(pushBwicActions.stagedBiddingStartStage2), watchStartStage2);
}
