import { takeEvery, call, put } from 'redux-saga/effects';
import { helpResourcesActions } from '../actions/help-resources.actions';
import { errorActions } from '../actions/error.actions';
import { getType, ActionType } from 'typesafe-actions';
import { helpResourcesService } from '../services/help-resources.service';
import { HelpResourceCategory } from '../types/help-resources/HelpResourceCategory';
import { HelpResourceSectionArticlesList } from '../types/help-resources/HelpResourceArticle';
import { HelpResourceSearch } from '../types/help-resources/HelpResourceSearch';

function* watchCategoriesRequest() {
    try {
        const categories: HelpResourceCategory[] = yield call(helpResourcesService.fetchCategories);
        yield put(helpResourcesActions.fetchCategories.success(categories))
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(helpResourcesActions.fetchCategories.failure(e))
    }
}

function* watchSectionRequest(action: ActionType<typeof helpResourcesActions.fetchSection.request>) {
    try {
        const section: HelpResourceSectionArticlesList = yield call(helpResourcesService.fetchSection, action.payload.sectionId)
        yield put(helpResourcesActions.fetchSection.success({
            categoryName: section.categoryName,
            sectionName: section.sectionName,
            articles: section.articles
        }))
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(helpResourcesActions.fetchSection.failure(e));
    }
}

function* wathcSearchRequest(action: ActionType<typeof helpResourcesActions.fetchBySearch.request>) {
    try {
        const searchResults: HelpResourceSearch = yield call(
            helpResourcesService.fetchBySearch,
            action.payload.search,
            action.payload.categoryId
        );
        yield put(helpResourcesActions.fetchBySearch.success({
            results: searchResults.result,
            totalRecordNumber: searchResults.totalRecordNumber
        }))
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(helpResourcesActions.fetchBySearch.failure(e));
    }
}

function* wathcSearchLoadMoreRequest(action: ActionType<typeof helpResourcesActions.loadMoreSearch.request>) {
    try {
        const searchResults: HelpResourceSearch = yield call(
            helpResourcesService.fetchBySearch,
            action.payload.search,
            action.payload.categoryId,
            action.payload.page
        );
        yield put(helpResourcesActions.loadMoreSearch.success({
            results: searchResults.result,
            totalRecordNumber: searchResults.totalRecordNumber
        }))
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(helpResourcesActions.loadMoreSearch.failure(e));
    }
}

export function* watchHelpResources() {
    yield takeEvery(getType(helpResourcesActions.fetchCategories.request), watchCategoriesRequest)
    yield takeEvery(getType(helpResourcesActions.fetchSection.request), watchSectionRequest)
    yield takeEvery(getType(helpResourcesActions.fetchBySearch.request), wathcSearchRequest)
    yield takeEvery(getType(helpResourcesActions.loadMoreSearch.request), wathcSearchLoadMoreRequest)
}