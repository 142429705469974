import moment from 'moment';
import { mapValues, toPairs } from 'lodash';
import { ActionType, getType } from "typesafe-actions";
import { put, takeLatest, call, select } from "redux-saga/effects";
import { errorActions } from "../actions";
import { cloManagersActions } from '../actions/clo-managers.actions';
import { routes } from "../constants";
import { CloManager } from '../types/clo-managers/CloManager';
import { cloManagersService } from '../services/clo-managers.service';
import { CloManagerDetailed } from '../types/clo-managers/CloManagerDetailed';
import { CloManagerSave } from '../types/clo-managers/CloManagerSave';
import { AppState } from '../types/state/AppState';
import { CloManagerInvestmentTeamMember } from '../types/clo-managers/CloManagerInvestmentTeamMember';
import { formatUtils } from '../utils';
import { CloManagersTabType } from '../types/clo-managers/CloManagersTabType';
import { compareDates } from '../utils/compare.utils';
import { CloManagerAnalytics } from '../types/clo-managers/CloManagerAnalytics';
import { User } from '../types/management/User';
import { transformToTreeSelect } from '../utils/analytics.utils';
import { CloManagerSession, CloManagerClientActivity, CloManagerProfileView, CloManagerSessions } from '../types/amr-pipeline/models/CloManagerSession';
import { CloManagerAccessType } from '../types/amr-pipeline/enums/CloManagerAccessType';
import { InvestmentTeamMember } from '../types/amr-pipeline/models/InvestmentTeamMember';
import { groupDocsWithAccessType } from '../utils/analytics.utils';
import { history } from '../history';
import { amrCompaniesService } from '../services/amr-companies.service';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';

const UserAccessTypes = [
    CloManagerAccessType.OverviewTab,
    CloManagerAccessType.DealTab,
    CloManagerAccessType.CloTeamTab,
    CloManagerAccessType.AdditionalInformationTab,
];

function groupSessionsByAccessType(sessions: CloManagerSession[]) {
    return sessions
        .reduce((acc: CloManagerProfileView[], session: CloManagerSession) => {
            const views = mapValues(session.tabsViews, x => x.length);
            const documents = groupDocsWithAccessType(session.documents);

            return [
                ...acc,
                {
                    ...session,
                    views,
                    documents,
                } as CloManagerProfileView
            ];
        }, [])
        .sort((a, b) => compareDates(b.accessDateTime, a.accessDateTime));
}

function groupSessionsByDate(sessions: CloManagerSession[]) {
    return sessions.reduce((acc: CloManagerClientActivity[], session: CloManagerSession) => {
        const grouped = toPairs(session.tabsViews);
        const documents = groupDocsWithAccessType(session.documents);

        return [
            ...acc,
            ...grouped.map(([accessType, entries]) => ({
                ...session,
                accessType,
                numberOfAccess: entries.length,
                documents,
            } as CloManagerClientActivity)),
        ];
    }, []);
}

function* watchGetCloManagers(action: ActionType<typeof cloManagersActions.getCloManagers>) {
    const { companyReferenceName } = action.payload;
    try {
        const data: CloManager[] = yield call(amrCompaniesService.getManagersList, HavingDealType.CloManagers);

        const favorites: CloManager[] = yield call(amrCompaniesService.getManagersList, HavingDealType.CloManagers, true);

        const all = data.map((cloManager) => ({
            ...cloManager,
            favorite: favorites.some(x => x.referenceName === cloManager.referenceName),
            isUserCompany: cloManager.referenceName === companyReferenceName,
        }));

        yield put(cloManagersActions.getCloManagersResult(all));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchGetCloManager(action: ActionType<typeof cloManagersActions.getCloManager>) {
    try {
        const { referenceName } = action.payload;

        const cloManager: CloManagerDetailed = yield call(cloManagersService.getCloManager, referenceName);
        const bwicCloManagers: User[] = yield call(cloManagersService.getInvitedAndActiveManagers, referenceName);

        const formattedBwicCloManagers = bwicCloManagers.map(m => ({
            status: m.status,
            fullName: `${m.firstName} ${m.lastName}`,
            linkedIn: m.linkedIn || "",
            title: m.jobTitle || "",
            email: m.email,
            phoneNumber: m.phone || "",
            location: m.location || "",
            id: m.id,
            meetingContact: m.meetingContact
        }));

        yield put(cloManagersActions.getCloManagerResult(
            referenceName,
            cloManager,
            formattedBwicCloManagers
        ));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchCreateOrUpdateCloManager(action: ActionType<typeof cloManagersActions.createOrUpdateCloManager>) {
    try {
        const { referenceName, cloManager } = action.payload;
        const editBwicCloManagers: InvestmentTeamMember[] = yield select((state: AppState) => state.cloManagers.editBwicCloManagers);


        const { dataItems, isValid } = yield select((state: AppState) => state.grid);
        const investmentTeam = dataItems.reduce(
            (accumulator: CloManagerInvestmentTeamMember[], value: any, index: number) =>
                value.draft
                    ? accumulator
                    : [
                          ...accumulator,
                          {
                              ...value,
                              order: index + 1,
                              linkedIn: formatUtils.formatUrlWithProtocol(
                                  value.linkedIn
                              ),
                          },
                      ],
            []
        );

        if (!isValid && investmentTeam.length) {
            return;
        }

        yield put(cloManagersActions.setCloManagerSaveState(true));

        const cloManagerSave: CloManagerSave = {
            ...cloManager,
            webSite: formatUtils.formatUrlWithProtocol(cloManager.webSite),
            linkedIn: formatUtils.formatUrlWithProtocol(cloManager.linkedIn),
            unpriSignatory: formatUtils.formatUrlWithProtocol(cloManager.unpriSignatory) || undefined,
            unpriSignatorySince: cloManager.unpriSignatorySince || undefined,
            description:
                cloManager.description !== cloManager.generatedDescription ? cloManager.description : undefined,
            investmentTeam,
            platformUserMeetingContacts: editBwicCloManagers.map((manager) => ({email: manager.email, meetingContact: !!manager.meetingContact})),
        };

        yield call(cloManagersService.createOrUpdateCloManager, referenceName, cloManagerSave);
        yield put(cloManagersActions.getCloManager(referenceName));
        yield call(history.push, routes.manageCloManagerUrl(referenceName, CloManagersTabType.Overview));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(cloManagersActions.setCloManagerSaveState(false));
    }
}

function* watchLogUserActivity(action: ActionType<typeof cloManagersActions.logUserActivity>) {
    const { companyReferenceName, accessType } = action.payload;
    try {
        yield call(cloManagersService.logUserActivity, companyReferenceName, accessType);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchSwitchFavorite(action: ActionType<typeof cloManagersActions.switchFavoriteRequest>) {
    const { companyReferenceName, favorite } = action.payload;

    try {
        yield call(cloManagersService.switchFavorite, companyReferenceName, favorite);
        yield put(cloManagersActions.switchFavoriteResponse(companyReferenceName, favorite));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchAnalyticsInit(action: ActionType<typeof cloManagersActions.analyticsInit>) {
    try {
        const { companyReferenceName } = action.payload;

        const { items, numberOfHiddenItems }: CloManagerSessions = yield call(cloManagersService.getProfileViewHistory, companyReferenceName);
        const analytics: CloManagerAnalytics = yield call(cloManagersService.getAnalytics, companyReferenceName);

        const profileViews = groupSessionsByDate(items);

        const users = transformToTreeSelect(profileViews, UserAccessTypes);

        yield put(cloManagersActions.analyticsInitResponse({ users, analytics, numberOfHiddenItems }));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchClientsActivityRequest(action: ActionType<typeof cloManagersActions.clientsActivityRequest>) {
    try {
        const { companyReferenceName, startDate, endDate } = action.payload;

        const { items, numberOfHiddenItems }: CloManagerSessions = yield call(
            cloManagersService.getProfileViewHistory,
            companyReferenceName,
            startDate ? moment(startDate).startOf('day').toDate() : undefined,
            endDate ? moment(endDate).endOf('day').toDate() : undefined,
        );

        const cloManagerClientsActivity = groupSessionsByDate(items)
            .filter(x => UserAccessTypes.includes(x.accessType));

        yield put(cloManagersActions.clientsActivityResponse(cloManagerClientsActivity, numberOfHiddenItems));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchProfileViewHistoryRequest(action: ActionType<typeof cloManagersActions.profileViewHistoryRequest>) {
    try {
        const { companyReferenceName, startDate, endDate } = action.payload;

        const alignedStartDate = startDate ? moment(startDate).startOf('day').toDate() : undefined;
        const alignedEndDate = endDate ? moment(endDate).endOf('day').toDate() : undefined;

        const { items, numberOfHiddenItems }: CloManagerSessions = yield call(
            cloManagersService.getProfileViewHistory,
            companyReferenceName,
            alignedStartDate,
            alignedEndDate
        );

        const profileViewHistory = groupSessionsByAccessType(items);

        yield put(cloManagersActions.profileViewHistoryResponse(profileViewHistory, numberOfHiddenItems));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchProfileViewHistoryFiltrByBar(action: ActionType<typeof cloManagersActions.profileViewHistoryFilterByBar>) {
    const { companyReferenceName, date } = action.payload;

    yield put(
        cloManagersActions.profileViewHistoryRequest(
            companyReferenceName,
            date,
            date
        )
    );
}

export function* watchCloManagers() {
    yield takeLatest(getType(cloManagersActions.getCloManagers), watchGetCloManagers);
    yield takeLatest(getType(cloManagersActions.getCloManager), watchGetCloManager);
    yield takeLatest(getType(cloManagersActions.createOrUpdateCloManager), watchCreateOrUpdateCloManager);
    yield takeLatest(getType(cloManagersActions.logUserActivity), watchLogUserActivity);
    yield takeLatest(getType(cloManagersActions.switchFavoriteRequest), watchSwitchFavorite);
    yield takeLatest(getType(cloManagersActions.analyticsInit), watchAnalyticsInit);
    yield takeLatest(getType(cloManagersActions.clientsActivityRequest), watchClientsActivityRequest);
    yield takeLatest(getType(cloManagersActions.profileViewHistoryRequest), watchProfileViewHistoryRequest);
    yield takeLatest(getType(cloManagersActions.profileViewHistoryFilterByBar), watchProfileViewHistoryFiltrByBar);
}
