import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import { isEqual, capitalize } from 'lodash';
import moment from 'moment';
import {
    dashboardInit,
    dashboardInitSuccess,
    dashboardFetchPortfolioExist,
    dashboardFetchPortfolioSecurities,
    dashboardFetchDntRates,
    dashboardFetchColorRates,
    dashboardFetchSameDayPeriods,
    dashboardFetchTradingStats,
    dashboardReset,
    dashboardOnSelectedSecurity,
    dashboardSetSearchTermItem,
    dashboardFetchSecurityHistoryRequest,
    dashboardFetchSecurityHistorySuccess,
    dashboardFetchSecurityHistoryFailure,
    dashboardExportBwiStatistic,
    dashboardExportTradingStats,
    dashboardSetRequestStatusExportTradingStats,
    dashboardFetchPriceLevel,
    dashboardFetchDtcSellingBuying,
    dashboardFetchAAAPriceLevel,
    dashboardFetchMostTradedTickers,
    dashboardFetchBiggestMovers,
    dashboardFetchDtcIgHy,
    dashboardFetchPxTalkDeviation,
    dashboardFetchBwicPercentOfTrace,
    dashboardFetchKWatchNews,
    dashboardSetFilterActive,
    dashboardSetNotAppliedFilter,
    dashboardSetFilter,
    dashboardClearFilter,
    dashboardFetchTopIssuers,
    dashboardFetchTopIssuersResult,
    dashboardExportTopIssuers,
    dashboardSetRequestStatusExportTopIssuers,
    dashboardFetchTopArrangers,
    dashboardFetchTopArrangersResult,
    dashboardExportTopArrangers,
    dashboardSetRequestStatusExportTopArrangers,
    dashboardFetchTopTrustees,
    dashboardFetchTopTrusteesResult,
    dashboardExportTopTrustees,
    dashboardSetRequestStatusExportTopTrustees,
    dashboardIssueTransactionVolumeRequest,
    dashboardIssueTransactionVolumeResult,
    dashboardUsBslEsgNewIssueRequest,
    dashboardUsBslEsgNewIssueResult,
    dashboardUsBslEuCompliantDealsRequest,
    dashboardUsBslEuCompliantDealsResult,
    dashboardGetPortfolioInventorySecuritiesRequest,
    dashboardGetPortfolioInventorySecuritiesSuccess,
    dashboardGetPortfolioInventorySecuritiesFailure,
    dashboardFetchTopStaticDeals,
    dashboardFetchTopStaticDealsResult,
    dashboardExportTopStaticDeals,
    dashboardSetRequestStatusExportTopStaticDeals,
    dashboardFetchTopDebutDeals,
    dashboardFetchTopDebutDealsResult,
    dashboardExportTopDebutDeals,
    dashboardSetRequestStatusExportTopDebutDeals,
    dashboardShortLongDatedDealsRequest,
    dashboardShortLongDatedDealsResult,
    dashboardIssuanceSpreadRequest,
    dashboardIssuanceSpreadResult,
    dashboardFetchKIndicator,
    dashboarNewIssuedWeeklySpreadsRequest,
    dashboardNewIssueWeeklySpreadsResult,
    dashboardInitPortfolioSecurities,
    dashboardInitTradingStats,
    dashboardInitPriceLevel,
    dashboardInitIssueTransactionVolume,
} from '../actions/dashboard.actions';
import { accountActions, constants, routes, pipelineRequestCriteria } from '../constants';
import { queryStringSerializer } from '../utils/filtering/query-string-serializer';
import { multipleCurrencies, ratingsGroup } from '../utils/filtering/serializers/serializer.definitions';
import { dashboardService, portfolioService, bwicService, newsService } from '../services';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { user } from '../user';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';
import { TransactionType } from '../types/amr-pipeline/enums/TransactionType';
import { Term } from '../types/dashboard/AmrChart';
import { TransactionStatus } from '../types/amr-pipeline/enums/TransactionStatus'
import { hasWidgetsAccess, hasKWatchNewsAccess } from '../utils/amr-pipeline.utils';
import { numericUtils } from '../utils';
import { TopChartType } from '../types/dashboard/TopChartType';
import { history } from '../history';

function* exportTopChart(exportFile, actionTypeRequest, chartName, ...params) {
    if (!hasWidgetsAccess()) {
        return;
    }
    yield put(actionTypeRequest(RequestState.request));
    try {
        const file = yield call(exportFile, ...params);
        saveAs(file.blob, `${chartName} - ${moment().format(constants.dateFormat)}.xlsx`);
        yield put(actionTypeRequest(RequestState.success));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(actionTypeRequest(RequestState.failure));
    }
}

function* exportTopCommonCharts(chartType, actionTypeRequest, payload) {
    const { types, ccy, yearFrom, yearTo, sortingParams, collateralTypes } = payload;

    yield exportTopChart(
        dashboardService.exportTopChart,
        actionTypeRequest,
        `Top-10 ${capitalize(chartType)}`,
        chartType,
        types,
        ccy,
        yearFrom,
        yearTo,
        collateralTypes,
        sortingParams
    );
}

function* fetchRequest(requestFunction, actionTypeRequest, isRestricted, ...args) {
    if (isRestricted) {
        return true;
    }
    try {
        yield put(actionTypeRequest(RequestState.request));
        const response = yield call(requestFunction, ...args);
        yield put(actionTypeRequest(RequestState.success, response));
    } catch (e) {
        yield put(actionTypeRequest(RequestState.failure));
        if (Number(e.status) === 401) {
            yield call(history.replace, routes.logout);
            return true;
        } else if (!errorActions.isRequestCancelationError(e)) {
            return false;
        }
    }
    return true;
}

function* watchDashboardInit() {
    try {
        const { dashboard } = yield select(s => s);
        const { filter } = dashboard;
        const queryString = history.location.search;
        if (queryString) {
            let selectedCurrencies = [];
            let selectedRatings = [];
            const serializers = [
                multipleCurrencies(parsedCurrency => selectedCurrencies = parsedCurrency),
                ratingsGroup(parsedRatings => selectedRatings = parsedRatings)
            ];
            queryStringSerializer.deserialize(queryString, serializers);
            const filterData = { selectedCurrencies, selectedRatings };
            yield put(dashboardSetNotAppliedFilter(filterData));
            yield put(dashboardSetFilter(filterData));
        } else if (filter.selectedRatings.length || filter.selectedCurrencies.length) {
            yield put(dashboardSetFilter(filter));
        }

        yield put(dashboardInitSuccess());

        const requests = [
            fetchRequest(dashboardService.fetchColorRates, dashboardFetchColorRates, !user.hasFeatures(SubscriptionFeature.getColorRates)),
            fetchRequest(dashboardService.fetchDntRates, dashboardFetchDntRates, !user.hasFeatures(SubscriptionFeature.getDntRates)),
            fetchRequest(dashboardService.fetchSameDayPeriods, dashboardFetchSameDayPeriods, !user.hasFeatures(SubscriptionFeature.getSameDayBwics)),
            fetchRequest(dashboardService.fetchDtcSellingBuying, dashboardFetchDtcSellingBuying, !user.hasFeatures(SubscriptionFeature.getDtcSellingBuying)),
            fetchRequest(dashboardService.fetchAAAPriceLevel, dashboardFetchAAAPriceLevel, !user.hasFeatures(SubscriptionFeature.getMedianTripleA)),
            fetchRequest(dashboardService.fetchMostTradedTickers, dashboardFetchMostTradedTickers, !user.hasFeatures(SubscriptionFeature.getMostTradedTickers)),
            fetchRequest(dashboardService.fetchBiggestMovers, dashboardFetchBiggestMovers, !user.hasFeatures(SubscriptionFeature.getBiggestMovers)),
            fetchRequest(dashboardService.fetchDtcInvestmentGrade, dashboardFetchDtcIgHy, !user.hasFeatures(SubscriptionFeature.getVantageData)),
            fetchRequest(dashboardService.fetchPxTalkDeviation, dashboardFetchPxTalkDeviation, !user.hasFeatures(SubscriptionFeature.getPriceTalkDeviation)),
            fetchRequest(dashboardService.fetchBwicPercentOfTrace, dashboardFetchBwicPercentOfTrace, !user.hasFeatures(SubscriptionFeature.getBwicPercentOfTrace)),
            fetchRequest(newsService.getNews, dashboardFetchKWatchNews, !hasKWatchNewsAccess(), pipelineRequestCriteria.kWatchNews),
            fetchRequest(dashboardService.fetchKIndicator, dashboardFetchKIndicator),
        ];

        const responses = yield all(requests);

        if (responses.some(r => !r)) {
            yield put(errorActions.unexpectedError());
        }

    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchInitIssueTransactionVolumeCount() {
    const response = yield call(
        fetchRequest,
        dashboardService.fetchIssueTransactionVolumesCount,
        dashboardIssueTransactionVolumeResult,
        !hasWidgetsAccess(),
        pipelineRequestCriteria.volumesCount
    );

    if (!response) {
        yield put(errorActions.unexpectedError());
    }
}

function* watchInitPortfolioSecurities() {
    const responses = yield all([
        fetchRequest(dashboardService.fetchPortfolioSecurities, dashboardFetchPortfolioSecurities, false),
        fetchRequest(portfolioService.portfolioExist, dashboardFetchPortfolioExist, false),
    ]);

    if (responses.some(r => !r)) {
        yield put(errorActions.unexpectedError());
    }
}

function* watchInitTradingStats() {
    const response = yield call(
        fetchRequest,
        dashboardService.fetchDailyTradingStats,
        dashboardFetchTradingStats,
        !user.hasFeatures(SubscriptionFeature.getTradingStats)
    );

    if (!response) {
        yield put(errorActions.unexpectedError());
    }
}

function* watchInitPriceLevel() {
    const response = yield call(
        fetchRequest,
        dashboardService.fetchPriceLevel,
        dashboardFetchPriceLevel,
        !user.hasFeatures(SubscriptionFeature.getPriceLevels)
    );

    if (!response) {
        yield put(errorActions.unexpectedError());
    }
}

function* watchLogout() {
    yield put(dashboardReset());
}

function* watchDashboardSetBoxChartSecurity() {
    try {
        const { searchTermItems = [], lookupCache = [] } = yield select(state => state.searchSecurities);
        yield put(dashboardSetSearchTermItem(searchTermItems[0] || ''));

        const [searchTerm] = searchTermItems;

        if (searchTerm) {
            const security = lookupCache.find(s =>
                searchTerm.localeCompare(s.ticker, undefined, { sensitivity: 'accent' }) ||
                searchTerm.localeCompare(s.isinCusip, undefined, { sensitivity: 'accent' })
            );

            if (security) {
                yield put(dashboardFetchSecurityHistoryRequest());
                const securityHistory = yield call(bwicService.getSecurityBwicStatistics, security.id);
                yield put(dashboardFetchSecurityHistorySuccess(securityHistory?.securities ?? []));
            }
        } else {
            yield put(dashboardFetchSecurityHistorySuccess([]));
        }
    } catch (e) {
        yield put(dashboardFetchSecurityHistoryFailure());
    }
}

function* watchDashboardExportBwiStatistic() {
    try {
        const { searchTermItems = [], lookupCache = [] } = yield select(state => state.searchSecurities);

        const security = lookupCache.find(s =>
            s.ticker === searchTermItems[0] ||
            s.isinCusip === searchTermItems[0])
            ;

        if (security) {
            const file = yield call(dashboardService.exportBwicStatistic, security.id);
            saveAs(file.blob, file.name);
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchExportTradingStats(action) {
    const { currencies, ratings } = action.payload;
    yield put(dashboardSetRequestStatusExportTradingStats(true));
    try {
        const file = yield call(dashboardService.fetchExportTradingStats, currencies, ratings);
        saveAs(file.blob, file.name);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(dashboardSetRequestStatusExportTradingStats(false));
    }
}

function* watchSetFilter(action) {
    const appliedFilter = yield select((state) => state.dashboard.filter);
    if (isEqual(action.payload.filter, appliedFilter)) {
        yield put(dashboardSetFilterActive(false));
    } else {
        yield put(dashboardSetFilterActive(true));
    }
}

function* watchDashboardSetFilter(action) {
    const { filter } = action.payload;
    const serializers = [multipleCurrencies(), ratingsGroup()];
    const criteria = {
        ratings: filter.selectedRatings,
        currency: filter.selectedCurrencies
    };
    const queryString = queryStringSerializer.serialize(criteria, serializers);
    const path = `${history.location.pathname}${queryString ? '?' + queryString : ''}`;
    yield call(history.replace, path);
}

function* watchDashboardClearFilter() {
    yield call(history.replace, history.location.pathname);
}

function* watchDashboardFetchTopIssuers(action) {
    const { types, ccy, year, collateralTypes } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Issuers.minYear;
    const yearTo = year ?? moment().year();

    yield fetchRequest(
        dashboardService.fetchTopChart,
        dashboardFetchTopIssuersResult,
        !hasWidgetsAccess(),
        TopChartType.issuers,
        types,
        ccy,
        yearFrom,
        yearTo,
        collateralTypes
    );
}

function* watchDashboardExportTopIssuers(action) {
    const { year, ...restPayload } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Issuers.minYear;
    const yearTo = year ?? moment().year();

    yield exportTopCommonCharts(
        TopChartType.issuers,
        dashboardSetRequestStatusExportTopIssuers,
        {
            ...restPayload,
            yearFrom,
            yearTo,
        },
    );
}

function* watchDashboardFetchTopArrangers(action) {
    const { types, ccy, year, collat } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Arrangers.minYear;
    const yearTo = year ?? moment().year();

    yield fetchRequest(
        dashboardService.fetchTopChart,
        dashboardFetchTopArrangersResult,
        !hasWidgetsAccess(),
        TopChartType.arrangers,
        types,
        ccy,
        yearFrom,
        yearTo,
        collat
    );
}

function* watchDashboardExportTopArrangers(action) {
    const { year, ...restPayload } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Arrangers.minYear;
    const yearTo = year ?? moment().year();

    yield exportTopCommonCharts(
        TopChartType.arrangers,
        dashboardSetRequestStatusExportTopArrangers,
        {
            ...restPayload,
            yearFrom,
            yearTo,
        },
    );
}

function* watchDashboardFetchTopTrustees(action) {
    const { types, ccy, year, collateralTypes } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Trustees.minYear;
    const yearTo = year ?? moment().year();

    yield fetchRequest(
        dashboardService.fetchTopChart,
        dashboardFetchTopTrusteesResult,
        !hasWidgetsAccess(),
        TopChartType.trustees,
        types,
        ccy,
        yearFrom,
        yearTo,
        collateralTypes
    );
}

function* watchDashboardExportTopTrustees(action) {
    const { year, ...restPayload } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.top10Trustees.minYear;
    const yearTo = year ?? moment().year();

    yield exportTopCommonCharts(
        TopChartType.trustees,
        dashboardSetRequestStatusExportTopTrustees,
        {
            ...restPayload,
            yearFrom,
            yearTo,
        },
    );
}

function* watchDashboardFetchTopStaticDeals(action) {
    const { year } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.staticDeals.minYear;
    const yearTo = year ?? moment().year();

    yield fetchRequest(
        dashboardService.fetchDeals,
        dashboardFetchTopStaticDealsResult,
        !hasWidgetsAccess(),
        pipelineRequestCriteria.staticDeals.additionalCriteria,
        yearFrom,
        yearTo,
    );
}

function* watchDashboardExportTopStaticDeals(action) {
    const { year, orderByTransaction } = action.payload;
    const payload = {
        orderByTransaction,
        staticDeal: true,
        exportedFrom: "staticDeals"
    };

    const yearFrom = year ?? pipelineRequestCriteria.staticDeals.minYear;
    const yearTo = year ?? moment().year();

    yield exportTopChart(
        dashboardService.fetchExportDeals,
        dashboardSetRequestStatusExportTopStaticDeals,
        "Static Deals",
        payload,
        yearFrom,
        yearTo
    );
}

function* watchDashboardFetchTopDebutDeals(action) {
    const { year } = action.payload;

    const yearFrom = year ?? pipelineRequestCriteria.debutDeals.minYear;
    const yearTo = year ?? moment().year();

    yield fetchRequest(
        dashboardService.fetchDeals,
        dashboardFetchTopDebutDealsResult,
        !hasWidgetsAccess(),
        pipelineRequestCriteria.debutDeals.additionalCriteria,
        yearFrom,
        yearTo,
    );
}

function* watchDashboardExportTopDebutDeals(action) {
    const { year, orderByTransaction } = action.payload;
    const payload = {
        orderByTransaction,
        isDebut: true,
        exportedFrom: "debutManagers"
    };

    const yearFrom = year ?? pipelineRequestCriteria.debutDeals.minYear;
    const yearTo = year ?? moment().year();

    yield exportTopChart(
        dashboardService.fetchExportDeals,
        dashboardSetRequestStatusExportTopDebutDeals,
        "Debut Managers",
        payload,
        yearFrom,
        yearTo
    );
}

function* watchIssueTransactionVolumeRequest(action) {
    const {
        filter: {
            transactionType,
            currencyCode,
        }
    } = action.payload;

    yield fetchRequest(
        dashboardService.fetchIssueTransactionVolumesCount,
        dashboardIssueTransactionVolumeResult,
        !hasWidgetsAccess(),
        {
            ...pipelineRequestCriteria.volumesCount,
            transactionType: transactionType === constants.allListItem
                ? undefined
                : transactionType,
            currencyCode,
        }
    );
}

function* watchUsBslEsgNewIssueRequest() {
    try {
        const from = pipelineRequestCriteria.usBslEsgNewIssue.startDateDefault;
        const to = moment.utc().toDate();

        yield put(dashboardUsBslEsgNewIssueResult(RequestState.request));

        const esgLanguageParams = {
            from,
            to,
            esg: true,
            collateralTypes: [CollateralType.broadlySyndicated],
            statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        };

        const usBslNewIssueParams = {
            from,
            to,
            collateralTypes: [CollateralType.broadlySyndicated],
            statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        };

        const [esgLanguageResult, usBslNewIssueResult] = yield all([
            call(dashboardService.fetchIssueTransactionVolumesCount, esgLanguageParams),
            call(dashboardService.fetchIssueTransactionVolumesCount, usBslNewIssueParams),
        ]);

        yield put(dashboardUsBslEsgNewIssueResult(RequestState.success, esgLanguageResult, usBslNewIssueResult));
    } catch (e) {
        yield put(dashboardUsBslEsgNewIssueResult(RequestState.failure));
    }
}

function* watchGetPortfolioInventorySecurities(action) {
    const { pageNumber, itemsOnPage, sortField, sortOrder } = action.payload;
    try {
        const securities = yield call(dashboardService.fetchPortfolioInventorySecurities, itemsOnPage, pageNumber, sortField, sortOrder);
        yield put(dashboardGetPortfolioInventorySecuritiesSuccess(securities.result, pageNumber, securities.totalRecordNumber));
    } catch (e) {
        yield put(dashboardGetPortfolioInventorySecuritiesFailure())
        yield put(errorActions.unexpectedError(e))
    }
}

function* watchUsBslEsgEuCompliantDealsRequest(action) {
    try {
        const { transactionType } = action.payload;

        const from = pipelineRequestCriteria.usBslEuCompliant.startDateDefault;
        const to = moment.utc().toDate();

        yield put(dashboardUsBslEuCompliantDealsResult(RequestState.request));

        const usBslEuCompliantDealsParams = {
            from,
            to,
            euCompliance: true,
            transactionType: transactionType === constants.allListItem
                ? [TransactionType.newIssue, TransactionType.refi, TransactionType.reset]
                : [transactionType],
            statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
            collateralTypes: [CollateralType.broadlySyndicated],
        };

        const usBslParams = {
            from,
            to,
            transactionType: transactionType === constants.allListItem
                ? [TransactionType.newIssue, TransactionType.refi, TransactionType.reset]
                : [transactionType],
            statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
            collateralTypes: [CollateralType.broadlySyndicated],
        };

        const [usBslEuCompliantDealsResult, usBslResult] = yield all([
            call(dashboardService.fetchVolumeCountByTransactionTypes, usBslEuCompliantDealsParams),
            call(dashboardService.fetchVolumeCountByTransactionTypes, usBslParams),
        ]);

        yield put(dashboardUsBslEuCompliantDealsResult(RequestState.success, usBslEuCompliantDealsResult, usBslResult));
    } catch (e) {
        yield put(dashboardUsBslEuCompliantDealsResult(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchShortLongDateDealsRequest(action) {
    try {
        const {
            filter: {
                transactionType,
                currencyCode,
            },
            managerReferenceName,
            startDate,
        } = action.payload;

        yield put(dashboardShortLongDatedDealsResult(RequestState.request));

        const shortDatedDealsParams = {
            ...pipelineRequestCriteria.shortLongDatedDeals,
            managerReferenceName,
            from: startDate,
            to: moment.utc().toDate(),
            currencyCode,
            transactionTypes: transactionType === constants.allListItem
                ? pipelineRequestCriteria.shortLongDatedDeals.transactionTypes
                : [transactionType],
        };

        const data = yield call(dashboardService.fetchVolumesCountShortLongDated, shortDatedDealsParams);

        yield put(dashboardShortLongDatedDealsResult(RequestState.success, data));
    } catch (e) {
        yield put(dashboardShortLongDatedDealsResult(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchIssuanceSpreadRequest(action) {
    try {
        const {
            managerReferenceName,
            filter: {
                transactionType,
                collateralType,
                rating,
                currencyCode,
                term,
                floaterIndex,
            },
            startDate,
        } = action.payload;

        yield put(dashboardIssuanceSpreadResult(RequestState.request));

        const { shortTerm, longTerm, ...defaultParams } = pipelineRequestCriteria.issuanceSpread;

        const termValuesByType = {
            [Term.Short]: shortTerm,
            [Term.Long]: longTerm,
        };

        let from = pipelineRequestCriteria.issuanceSpread.startDateDefault;
        let to = moment.utc().toDate();

        if (startDate) {
            from = startDate;
            to = moment.utc().toDate();
        }

        const issuanceSpreadParams = {
            ...defaultParams,
            managerReferenceName,
            from,
            to,
            transactionTypes: transactionType === constants.allListItem
                ? defaultParams.transactionTypes
                : [transactionType],
            collateralTypes: collateralType,
            floaterIndexes: floaterIndex === constants.allListItem
                ? defaultParams.floaterIndexes
                : [floaterIndex],
            classRatings: rating,
            currencyCodes: currencyCode,
            ...(term && termValuesByType[term]),
        };

        const data = yield call(dashboardService.fetchIssuanceSpreads, issuanceSpreadParams);

        const round = (value) => value ? numericUtils.round(value * 100, 0) : value;

        const issuanceSpreads = data.issuanceSpreads.map((spread) => ({
            ...spread,
            min: round(spread.min),
            q1: round(spread.q1),
            median: round(spread.median),
            q3: round(spread.q3),
            max: round(spread.max),
            count: round(spread.count),
            mean: round(spread.mean),
            bottomOutlier: round(spread.bottomOutlier),
            topOutlier: round(spread.topOutlier),
        }));

        const median = round(data.median);

        yield put(dashboardIssuanceSpreadResult(RequestState.success, { issuanceSpreads, median }));
    } catch (e) {
        yield put(dashboardIssuanceSpreadResult(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchNewIssueWeeklySpreadsRequest() {
    try {
        yield put(dashboardNewIssueWeeklySpreadsResult(RequestState.request));

        const data = yield call(dashboardService.fetchNewIssueWeeklySpreads);

        yield put(dashboardNewIssueWeeklySpreadsResult(RequestState.success, data));
    } catch (e) {
        yield put(dashboardNewIssueWeeklySpreadsResult(RequestState.failure));
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchDashboard() {
    yield takeLatest(getType(dashboardInit), watchDashboardInit);
    yield takeLatest(getType(dashboardInitIssueTransactionVolume), watchInitIssueTransactionVolumeCount);
    yield takeLatest(getType(dashboardInitPortfolioSecurities), watchInitPortfolioSecurities);
    yield takeLatest(getType(dashboardInitTradingStats), watchInitTradingStats);
    yield takeLatest(getType(dashboardInitPriceLevel), watchInitPriceLevel);
    yield takeLatest(getType(dashboardOnSelectedSecurity), watchDashboardSetBoxChartSecurity);
    yield takeLatest(accountActions.LOGOUT, watchLogout);
    yield takeLatest(dashboardExportBwiStatistic, watchDashboardExportBwiStatistic);
    yield takeLatest(dashboardExportTradingStats, watchExportTradingStats);
    yield takeLatest(dashboardSetNotAppliedFilter, watchSetFilter);
    yield takeLatest(dashboardSetFilter, watchDashboardSetFilter);
    yield takeLatest(dashboardClearFilter, watchDashboardClearFilter);
    yield takeLatest(dashboardFetchTopIssuers, watchDashboardFetchTopIssuers);
    yield takeLatest(dashboardExportTopIssuers, watchDashboardExportTopIssuers);
    yield takeLatest(dashboardFetchTopArrangers, watchDashboardFetchTopArrangers);
    yield takeLatest(dashboardExportTopArrangers, watchDashboardExportTopArrangers);
    yield takeLatest(dashboardFetchTopTrustees, watchDashboardFetchTopTrustees);
    yield takeLatest(dashboardExportTopTrustees, watchDashboardExportTopTrustees);
    yield takeLatest(dashboardFetchTopStaticDeals, watchDashboardFetchTopStaticDeals);
    yield takeLatest(dashboardExportTopStaticDeals, watchDashboardExportTopStaticDeals);
    yield takeLatest(dashboardFetchTopDebutDeals, watchDashboardFetchTopDebutDeals);
    yield takeLatest(dashboardExportTopDebutDeals, watchDashboardExportTopDebutDeals);
    yield takeLatest(getType(dashboardIssueTransactionVolumeRequest), watchIssueTransactionVolumeRequest);
    yield takeLatest(getType(dashboardUsBslEsgNewIssueRequest), watchUsBslEsgNewIssueRequest);
    yield takeLatest(getType(dashboardGetPortfolioInventorySecuritiesRequest), watchGetPortfolioInventorySecurities);
    yield takeLatest(getType(dashboardUsBslEuCompliantDealsRequest), watchUsBslEsgEuCompliantDealsRequest);
    yield takeLatest(getType(dashboardShortLongDatedDealsRequest), watchShortLongDateDealsRequest);
    yield takeLatest(getType(dashboardIssuanceSpreadRequest), watchIssuanceSpreadRequest);
    yield takeLatest(getType(dashboarNewIssuedWeeklySpreadsRequest), watchNewIssueWeeklySpreadsRequest);
}
