import { takeEvery, put } from 'redux-saga/effects';
import { getType, ActionType, PayloadAction } from 'typesafe-actions';
import { createFilterActions } from '../actions/filter.actions';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { TFilterType } from '../types/filters/FilterState';
import { getDateOptionByRange } from '../utils/filtering/filter.utils';

const actions = createFilterActions();

function* watchUpdateFilterState(action: PayloadAction<string, {filterType: TFilterType}>) {
    const filterType = action.payload.filterType;

    const filterActions = createFilterActions(filterType);

    yield put(filterActions.updateFilterState());
}

function* watchResetFiltersAndUnselectSavedFilter(
    action: ActionType<typeof actions.resetFiltersAndUnselectSavedFilter>,
) {
    const filterType = action.payload.filterType;

    const filterActions = createFilterActions(filterType);

    yield put(filterActions.resetFilter());
    yield put(filterActions.setFilterByReferenceName('', undefined));
}

function* watchRedirectWithNoFilter(action: ActionType<typeof actions.redirectWithNoFilter>) {
    const filterType = action.payload.filterType;

    const filterActions = createFilterActions(filterType);

    yield put(filterActions.setRedirectFilters({}));
}

function* watchSetFilterDateFromQueryString(action: ActionType<typeof actions.filterDateQueryStringDeserialized>) {
    const { filterType, filterName, deserializeResult } = action.payload;
    const filterActions = createFilterActions(filterType);
    if (deserializeResult.years && (deserializeResult.years.from || deserializeResult.years.to)) {
        const filterOption = getDateOptionByRange(dateRangeFilterOptions.YearsRange, deserializeResult.years.from, deserializeResult.years.to);
        yield put(filterActions.makeFilterVisible(filterName));
        yield put(filterActions.filterDateSelectOption(filterOption, filterName));
        yield put(
            filterActions.filterDateSelectYearsRange({ from: deserializeResult.years.from, to: deserializeResult.years.to }, filterName),
        );
    }
    if (deserializeResult.dates && (deserializeResult.dates.from || deserializeResult.dates.to)) {
        const filterOption = getDateOptionByRange(dateRangeFilterOptions.Custom, deserializeResult.dates.from, deserializeResult.dates.to)
        yield put(filterActions.makeFilterVisible(filterName));
        yield put(filterActions.filterDateSelectOption(filterOption, filterName));
        yield put(
            filterActions.filterDateSelectCustomRange({ from: deserializeResult.dates.from, to: deserializeResult.dates.to }, filterName),
        );
    }
}

export function* watchFilter() {
    yield takeEvery(
        [
            getType(actions.filterSelectChange),
            getType(actions.filterSelectClearAll),
            getType(actions.changeRangeFilter),
            getType(actions.filterRadioChange),
            getType(actions.filterRadioClearOption),
            getType(actions.filterRangeClearSelectedOptions),
            getType(actions.filterSelectSelectAll),
            getType(actions.filterDateClearSelectedOptions),
            getType(actions.filterDateSelectOption),
            getType(actions.filterDateTimeOptionSelected),
            getType(actions.filterDateSelectYearsRange),
            getType(actions.filterDateSelectCustomRange),
            getType(actions.filterDateTimeSelectCustomRange),
        ],
        watchUpdateFilterState,
    );
    yield takeEvery(getType(actions.resetFiltersAndUnselectSavedFilter), watchResetFiltersAndUnselectSavedFilter);
    yield takeEvery(getType(actions.redirectWithNoFilter), watchRedirectWithNoFilter);
    yield takeEvery(getType(actions.filterDateQueryStringDeserialized), watchSetFilterDateFromQueryString)
}
